<i18n locale="th" lang="yaml" src="@i18n/service/claim.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
onfield_claim.claim_parts : "อะไหล่ที่ทำการยื่นเคลม"
onfield_claim.claim_parts.description : "สามารถเคลมได้เฉพาะอะไหล่ที่มีเลข SKU ในระบบนี้เท่านั้น (ถ้ารายการไหนไม่มีรบกวนติดต่อทาง HGR เพื่อนำข้อมูลเข้าระบบ)"

</i18n>

<template>
	<div class="claim-parts-container">
		<div class="mytab-section-title">
			{{$t('onfield_claim.claim_parts')}}
			<div class="mytab-section-description">
				{{$t('onfield_claim.claim_parts.description')}}
			</div>
		</div>

		<div class="claim-subsection">
			<ServicePartList
				ref="claimPartsRef"
				:list-mode="listMode"
				:readonly="!canUpdate"
				:service="service"
				:onfield-requested="true"
				:mode="partMode"
				@claim="claimCheck"/>
		</div>

	</div>
</template>

<script>
import ServicePartList from "@components/service/ServicePartList.vue"


export default {
	components : {
		ServicePartList ,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean ,
			default : false,
		}
	} ,
	data() {
		return {
			listMode : 'default',
			partMode : 'claim-request',
			showRecallParts : false,
		}
	} ,
	methods : {
		setData(claimRequest,serviceParts) {
			switch (claimRequest.status) {
				case 'draft' :
					this.listMode = 'default'
					this.partMode = 'claim-request'
					break;
				case 'draft_appeal' :
					this.listMode = 'default'
					this.partMode = 'claim-appeal'
					break;
				case 'request' :
				case 'appeal' :
					this.listMode = 'requested-only'
					this.partMode = 'claim-decision'
					break;
				case 'approved' :
					this.listMode = 'approved-only'
					this.partMode = 'claim-concluded'
					break;
				case 'rejected' :
					this.listMode = 'requested-only'
					this.partMode = 'claim-concluded'
					break;
				default :
					this.listMode = 'default'
					this.partMode = 'claim-display'
					break;
			}
			if ('repair' in serviceParts) {
				this.$refs.claimPartsRef.setData(serviceParts.repair)
			} else {
				this.$refs.claimPartsRef.setData([])
			}
		} ,
		getData() {
			return this.$refs.claimPartsRef.getData()
		} ,
		claimCheck(val) {
			this.$emit('claim', val)
		}
	}
}
</script>

<style lang="less" scoped>
@import 'claim.less';
.part-display-select {
	display : block;
	margin-bottom : 8px;
	width : 200px;
}
</style>
